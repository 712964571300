<template>
  <div class="clearBox">
    <ayl-berad-nav :nav="nav"></ayl-berad-nav>
    <div class="content-box">
    <ayl-main title="基本信息" :isCollapse="false">
      <div slot="main" class="p24px">
		  <div class="contents">
			<span>角色名称:</span>
			<span> {{ data.roleName ||'- -' }}</span>
		  </div>
		  <div class="contents">
		  	<span>角色状态:</span>
		  	<span> {{ data.status ||'- -' }}</span>
		  </div>
		  <div style="width: 100%;">
		    <span style="color: #262626;">权限名称:</span>
		    <span style="padding: 0 5px;">{{ stropList(data.permissionNameList) || '- -' }}</span>
		  </div>
      </div>
    </ayl-main>
    <ayl-main title="其他信息(选填)" :isCollapse="false">
      <div slot="main" class="p24px">
        <div style="width: 100%;">
        	<span style="color: #262626;">备注:</span>
        	<span> {{ data.remark ||'- -' }}</span>
        </div>
      </div>
    </ayl-main>
    <div class="tac" style="margin-top: 40px">
      <el-button style="margin-left: 30px" @click="$router.push('/sanitation-system-management/role-manage/role-manage')">返回</el-button>
    </div>
    </div>
  </div>
</template>

<script>
  export default {
    data () {
      return {
        nav: [
          {name: "查看", path: ""},
        ],
		data:{},
        form: {
          name: null,
          textarea: null
        },
        rules: {
          // name: {required: true, message: '请选择审核状态', trigger: 'change'},
        },
        submitLoading: false
      }
    },
    methods:{
      stropList(e){
        let str = ''
        str = ( e || []).join('、')
        return str
      }
    },
    async mounted () {
		this.data = await this.$api.getDetail({
		  roleIdentity: this.$route.query.roleIdentity
		})
    }
  }
</script>
<style lang='sass' scoped>
</style>
